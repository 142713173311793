import { useRouter } from "next/router";
import { useState } from "react";
import Link from "next/link";
import { RiShieldKeyholeFill } from "react-icons/ri";
import { FaBitcoin, FaEthereum } from "react-icons/fa";
import { FiMenu, FiX } from "react-icons/fi";
import { BsPatchQuestion } from "react-icons/bs";

const Header = () => {
  const router = useRouter();
  const [toggle, setToggle] = useState(false);

  const triggerToggle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <header className="p-4">
        <div className="container flex justify-between h-16 mx-auto">
          <Link href="/">
            <a className="flex items-center p-2 text-red-400 text-2xl">
              <RiShieldKeyholeFill className="w-8 h-8 mr-2" />
              PrivateKeys.in
            </a>
          </Link>
          <ul className="items-stretch hidden space-x-3 md:flex">
            <li className="flex">
              <Link href="/about">
                <a
                  className={`flex items-center px-4 -mb-1 border-b-2 border-transparent hover:text-red-300 ${
                    router.pathname == "/about"
                      ? "text-red-400 border-red-400"
                      : ""
                  }`}
                >
                  <BsPatchQuestion className="w-8 h-8 mr-2" />
                  About
                </a>
              </Link>
            </li>
            <li className="flex">
              <Link href="/bitcoin">
                <a
                  className={`flex items-center px-4 -mb-1 border-b-2 border-transparent hover:text-red-300 ${
                    router.pathname == "/bitcoin"
                      ? "text-red-400 border-red-400"
                      : ""
                  }`}
                >
                  <FaBitcoin className="w-8 h-8 mr-2" />
                  Bitcoin
                </a>
              </Link>
            </li>
            <li className="flex">
              <Link href="/ethereum">
                <a
                  className={`flex items-center px-4 -mb-1 border-b-2 border-transparent hover:text-red-300 ${
                    router.pathname == "/ethereum"
                      ? "text-red-400 border-red-400"
                      : ""
                  }`}
                >
                  <FaEthereum className="w-8 h-8 mr-1" />
                  Ethereum
                </a>
              </Link>
            </li>
          </ul>
          <button
            onClick={triggerToggle}
            className="flex justify-end p-4 md:hidden"
          >
            <FiMenu className={`text-2xl ${toggle ? "hidden" : ""}`} />
            <FiX className={`text-2xl ${toggle ? "" : "hidden"}`} />
          </button>
        </div>
        {toggle && (
          <>
            <ul className="mt-4 space-y-6">
              <li>
                <Link href="/about">
                  <a
                    className={`flex items-center px-4 -mb-1 border-b-2 border-transparent hover:text-red-300 ${
                      router.pathname == "/about" ? "text-red-400" : ""
                    }`}
                  >
                    <BsPatchQuestion className="w-6 h-6 mr-2" />
                    About
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/bitcoin">
                  <a
                    className={`flex items-center px-4 -mb-1 border-b-2 border-transparent hover:text-red-300 ${
                      router.pathname == "/bitcoin" ? "text-red-400" : ""
                    }`}
                  >
                    <FaBitcoin className="w-6 h-6 mr-2" />
                    Bitcoin Private Keys
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/ethereum">
                  <a
                    className={`flex items-center px-4 -mb-1 border-b-2 border-transparent hover:text-red-300 ${
                      router.pathname == "/ethereum" ? "text-red-400" : ""
                    }`}
                  >
                    <FaEthereum className="w-6 h-6 mr-1" />
                    Ethereum Private Keys
                  </a>
                </Link>
              </li>
            </ul>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
