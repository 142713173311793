import type { ReactNode } from "react";
import Head from "next/head";
import Footer from "../main/Footer";
import Header from "../main/Header";

const MainLayout = ({
  children,
  title = "PrivateKeys.in - Crypto Keys Database",
}: {
  children: ReactNode;
  title?: string;
}) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <div className="space-y-4 bg-gray-800 text-gray-100">
        <Header />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
