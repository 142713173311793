import Link from "next/link";
import { RiShieldKeyholeFill } from "react-icons/ri";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container flex flex-col p-4 mx-auto md:p-8 lg:flex-row">
          <ul className="self-center py-6 space-y-4 text-center sm:flex sm:space-y-0 sm:justify-around sm:space-x-4 lg:flex-1 lg:justify-start">
            <li>
              <Link href="/about">
                <a>About</a>
              </Link>
            </li>
            <li>
              <Link href="/bitcoin">
                <a>Bitcoin Private Keys</a>
              </Link>
            </li>
            <li>
              <Link href="/ethereum">
                <a>Ethereum Private Keys</a>
              </Link>
            </li>
          </ul>
          <div className="flex flex-col justify-center pt-6 lg:pt-0">
            <div className="flex justify-center space-x-4">
              <Link href="/">
                <a className="flex" title="PrivateKeys.in">
                  <RiShieldKeyholeFill className="w-8 h-8 sm:w-10 sm:h-10 text-red-400" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
