import type { NextPage } from "next";
import Head from "next/head";
import Link from "next/link";
import MainLayout from "../components/layouts/MainLayout";
import Hero from "../components/main/Hero";

const Home: NextPage = () => {
  return (
    <>
      <MainLayout>
        <Hero />
        <section>
          <div className="container mx-auto">
            <div className="p-4 mx-auto text-center md:px-10 lg:px-32 xl:max-w-5xl">
              <h2 className="text-2xl font-bold leading-none sm:text-4xl">
                How does this work?
              </h2>
              <p className="px-8 my-4 text-justify">
                A private key is basically just a number between 1 and 2
                <sup>256</sup>. This website generates keys for all of those
                numbers, spread out over pages of 128 keys each.
              </p>
              <p className="px-8 my-4 text-justify">
                This website doesn&apos;t actually have a database of all
                private keys, that would take an impossible amount of disk
                space. Instead, keys are procedurally generated on the fly when
                a page is opened. The page number is used to calculate which
                keys should be on that page.
              </p>
              <p className="px-8 my-4 text-justify">
                Finding an active wallet is hard, but not impossible. Every time
                you open a random page, you have a chance of finding someone
                else&apos;s fortune.
              </p>
              <p className="px-8 my-4 text-justify">
                If you&apos;re curious which page your wallet is on, you could
                do a
                <Link href="/bitcoin/search">
                  <a>search</a>
                </Link>{" "}
                for it. That will show you exactly which page your wallet is on.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto">
            <div className="p-4 mx-auto text-center md:px-10 lg:px-32 xl:max-w-5xl">
              <h2 className="text-2xl font-bold leading-none sm:text-4xl">
                Automatic balance checking
              </h2>
              <p className="px-8 my-4 text-justify">
                The balance of each wallet is automatically checked. Wallets
                with a balance are colored green. Wallets that have been used in
                the past but are now empty will turn yellow. Wallets that have
                never been used are red.
              </p>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default Home;
