import Link from "next/link";
import { FaBitcoin, FaEthereum } from "react-icons/fa";

const Hero = () => {
  return (
    <>
      <section>
        <div className="container flex flex-col items-center px-4 py-8 mx-auto text-center md:px-10 lg:px-32 xl:max-w-5xl">
          <h1 className="text-4xl font-bold leading-none sm:text-5xl">
            Every{" "}
            <span className="text-[#f7931a]">
              <FaBitcoin className="inline mr-2" />
              Bitcoin
            </span>{" "}
            and{" "}
            <span className="text-[#a6deec]">
              <FaEthereum className="inline mr-1" />
              Ethereum
            </span>{" "}
            private key is listed on this website
          </h1>
          <p className="px-8 mt-8 mb-12 text-lg">
            Yes, your private key is on this website too, but don&apos;t worry,
            nobody will ever find it. If you want to try searching for your
            wallet, click one of the buttons below.
          </p>
          <div className="flex flex-wrap justify-center">
            <Link href="/bitcoin/random">
              <a className="px-8 py-3 m-2 text-lg font-semibold rounded bg-[#f7931a] text-gray-900">
                <FaBitcoin className="inline mr-2" />
                Find Bitcoin Keys
              </a>
            </Link>
            <Link href="/ethereum/random">
              <a className="px-8 py-3 m-2 text-lg font-semibold rounded bg-[#a6deec] text-gray-900">
                <FaEthereum className="inline mr-1" />
                Find Ethereum Keys
              </a>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
